import {ReactNode} from 'react';
import {Icon} from './Icon';

export const Card = ({
  title,
  icon,
  children,
}: {
  title?: string;
  icon?: any;
  children: ReactNode;
}) => {
  return (
    <div className='p-5 pt-8 bg-gradient-to-tr from-theme-dark-blue to-theme-blue text-white rounded-xl m-2 lg:m-5 text-center'>
      {icon && (
        <div className='flex justify-center mb-5'>
          <Icon iconName={icon} size={50} />
        </div>
      )}
      {title && <h1 className='font-bold text-2xl mb-5'>{title}</h1>}
      <div>{children}</div>
    </div>
  );
};
