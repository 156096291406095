import emailjs from 'emailjs-com';

interface EmailOptions {
  name: string;
  email: string;
  description: string;
}

export const sendEmail = async ({
  name,
  email,
  description,
}: EmailOptions): Promise<void> => {
  const templateParams = {
    user_name: name,
    user_email: email,
    user_message: description,
    user_subject: 'My Successful Company',
    to_email: '', // Placeholder for the recipient email, which we'll update for each send
    email_year: new Date().getFullYear(),
  };

  try {
    // Send email to info@mysuccessful.company
    await emailjs.send(
      'service_3osbjpq', // Your EmailJS Service ID
      'template_vfpjfbi', // Your EmailJS Template ID
      {...templateParams, to_email: 'info@mysuccessful.company'}, // Send to info@mysuccessful.company
      'VLeZJcPkg_SJzndf8' // Your EmailJS User ID
    );

    console.log('Email sent to info@mysuccessful.company successfully.');

    // Send the same email to the user
    await emailjs.send(
      'service_3osbjpq', // Same Service ID
      'template_wag00tb', // Same Template ID
      {...templateParams, to_email: email}, // Send to the user's email
      'VLeZJcPkg_SJzndf8' // Same User ID
    );

    console.log('Email sent successfully to:', email);
  } catch (error) {
    console.error('Failed to send email:', error);
    throw error;
  }
};
