export const Button = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => (
  <button
    onClick={onClick}
    className='bg-gradient-to-tr from-theme-dark-blue to-theme-blue hover:bg-blue-700 text-white font-bold py-2 px-6 rounded text-base'
  >
    {text}
  </button>
);
