import * as icons from 'react-bootstrap-icons';
import * as ioIcons from 'react-icons/io5';
import * as luIcons from 'react-icons/lu';
import * as mdIcons from 'react-icons/md';
import * as bsIcons from 'react-icons/bs';
import * as biIcons from 'react-icons/bi';
import * as faIcons from 'react-icons/fa6';
import * as hiIcons from 'react-icons/hi';
import React from 'react';
interface IconProps extends React.SVGProps<SVGSVGElement> {
  iconName:
    | keyof typeof icons
    | keyof typeof ioIcons
    | keyof typeof luIcons
    | keyof typeof mdIcons
    | keyof typeof bsIcons
    | keyof typeof biIcons
    | keyof typeof faIcons
    | keyof typeof hiIcons;
  className?: string;
  size?: number;
  color?: string;
}

export const Icon = ({
  iconName,
  className,
  size,
  color,
  ...props
}: IconProps) => {
  const BootstrapIcon = icons[iconName as keyof typeof icons];

  if (BootstrapIcon)
    return (
      <BootstrapIcon
        className={className}
        size={size}
        color={color}
        {...props}
      />
    );

  // Io Icons
  const IoIcon = ioIcons[iconName as keyof typeof ioIcons];
  if (IoIcon) {
    return (
      <IoIcon className={className} size={size} color={color} {...props} />
    );
  }

  // Lu Icons
  const LuIcon = luIcons[iconName as keyof typeof luIcons];
  if (LuIcon) {
    return (
      <LuIcon className={className} size={size} color={color} {...props} />
    );
  }

  const MdIcon = mdIcons[iconName as keyof typeof mdIcons];
  if (MdIcon) {
    return (
      <MdIcon className={className} size={size} color={color} {...props} />
    );
  }

  const BsIcon = bsIcons[iconName as keyof typeof bsIcons];
  if (BsIcon) {
    return (
      <BsIcon className={className} size={size} color={color} {...props} />
    );
  }
  const BiIcon = biIcons[iconName as keyof typeof biIcons];
  if (BiIcon) {
    return (
      <BiIcon className={className} size={size} color={color} {...props} />
    );
  }

  const FaIcon = faIcons[iconName as keyof typeof faIcons];
  if (FaIcon) {
    return (
      <FaIcon className={className} size={size} color={color} {...props} />
    );
  }

  const HiIcon = hiIcons[iconName as keyof typeof hiIcons];
  if (HiIcon) {
    return (
      <HiIcon className={className} size={size} color={color} {...props} />
    );
  }

  // If icon not found
  console.error(`Icon "${iconName}" not found in any provided library.`);
  return null;
};
