import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Main from './main';
import FeedbackFormCostaRicanAirOrganic from './qr/costaricanairorganic';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route
          path='/feedback/costaricanairorganic'
          element={<FeedbackFormCostaRicanAirOrganic />}
        />
      </Routes>
    </Router>
  );
};

export default App;
