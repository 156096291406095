// components/Input.tsx
import React, {ChangeEvent} from 'react';
import {AsYouType} from 'libphonenumber-js';

interface InputProps {
  type: 'text' | 'tel' | 'number' | 'email' | 'file';
  name: string;
  label: string;
  value?: string | number;
  placeholder?: string;
  min?: number;
  step?: number;
  pattern?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  accept?: string;
  multiple?: boolean;
  radioButtons?: boolean;
}

export const Input: React.FC<InputProps> = ({
  type,
  name,
  label,
  value,
  placeholder,
  min,
  step,
  pattern,
  onChange,
  required,
  accept,
  multiple,
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let formattedValue = e.target.value;

    if (type === 'tel') {
      const asYouType = new AsYouType(); // Dynamically format as the user types
      formattedValue = asYouType.input(formattedValue);
    }

    // Pass the formatted value to the parent component
    e.target.value = formattedValue;
    onChange(e);
  };

  return (
    <div>
      <label className='block mb-2 font-medium text-gray-900'>{label}</label>
      <input
        type={type}
        name={name}
        className='bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
        value={value}
        placeholder={placeholder}
        min={min}
        step={step}
        pattern={pattern}
        onChange={handleInputChange}
        required={required}
        accept={accept}
        multiple={multiple}
      />
    </div>
  );
};
