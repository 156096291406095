import {Button} from './Button';

export const NavBar = () => {
  const scrollToServices = () => {
    const servicesSection = document.getElementById('services');
    if (servicesSection) {
      servicesSection.scrollIntoView({behavior: 'smooth'});
    }
  };
  const scrollToQuoteForm = () => {
    const quoteFormSection = document.getElementById('quoteForm');
    if (quoteFormSection) {
      quoteFormSection.scrollIntoView({behavior: 'smooth'});
    }
  };

  return (
    <div className='flex justify-center lg:justify-between items-center h-28 mx-10'>
      <div
        id='logo'
        className='flex text-xl lg:text-3xl text-theme-dark-blue font-bold items-center -mt-[190px] lg:mt-0'
      >
        <img src='/logo192.png' height={60} width={60} />
        MySuccesful.Company
      </div>
      <div className='hidden lg:flex grid grid-cols-2 gap-4'>
        <Button text='Services' onClick={scrollToServices} />
        <Button text='Get a Quote' onClick={scrollToQuoteForm} />
      </div>
    </div>
  );
};
