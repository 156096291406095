import React, {useState, ChangeEvent, FormEvent} from 'react';
import {Input} from './Input';
import {sendEmail} from '../api/email';

interface FormData {
  name: string;
  email: string;
  description: string;
}

export const QuoteForm = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    description: '',
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      await sendEmail(formData);
      setSuccessMessage('Email sent successfully!');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Failed to send email. Please try again.');
      setSuccessMessage('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className='bg-white rounded-xl lg:p-10 p-5 lg:mx-0 mx-5'
      onSubmit={handleSubmit}
    >
      <h1 className='font-bold text-theme-blue text-center text-2xl'>
        You have a project in mind?
        <span className='font-normal'> Let's talk!</span>
      </h1>
      <div className='mb-3 mt-5'>
        <Input
          type='text'
          name='name'
          label='Name*'
          value={formData.name}
          placeholder='Your Name'
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <Input
          type='email'
          name='email'
          label='Email*'
          value={formData.email}
          placeholder='Contact Email'
          onChange={handleChange}
          required
        />
      </div>
      <div className='mt-5'>
        <label>Tell us about your project*</label>
        <textarea
          name='description'
          className='bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 my-2'
          placeholder='What do you expect to accomplish?'
          value={formData.description}
          rows={7}
          onChange={handleChange}
          required
        />
      </div>
      <button
        type='submit'
        className='mt-5 bg-theme-blue text-white py-2 px-10 rounded text-lg font-bold'
        disabled={loading}
      >
        {loading ? 'Sending...' : 'Send'}
      </button>

      {successMessage && (
        <p className='text-green-500 mt-4'>{successMessage}</p>
      )}
      {errorMessage && <p className='text-red-500 mt-4'>{errorMessage}</p>}
    </form>
  );
};
