import React, {useState, useEffect} from 'react';

interface StarRatingProps {
  maxRating: number;
  onRatingChange: (newRating: number) => void;
  currentRating: number;
  required?: boolean; // Add optional required parameter
}

const StarRating: React.FC<StarRatingProps> = ({
  maxRating,
  onRatingChange,
  currentRating,
  required = false, // Default to false if not provided
}) => {
  const [isTouched, setIsTouched] = useState(false); // Track if the user has interacted with the rating

  const handleStarClick = (rating: number) => {
    onRatingChange(rating);
    setIsTouched(true); // Mark as touched when the user clicks a star
  };

  useEffect(() => {
    if (currentRating > 0) {
      setIsTouched(true); // If a rating is already set, mark as touched
    }
  }, [currentRating]);

  return (
    <div className='flex items-center space-x-1'>
      {[...Array(maxRating)].map((_, index) => {
        const rating = index + 1;
        return (
          <button
            key={rating}
            onClick={() => handleStarClick(rating)}
            className={`text-xl ${
              rating <= currentRating ? 'text-yellow-500' : 'text-gray-300'
            }`}
          >
            ★
          </button>
        );
      })}
    </div>
  );
};

export default StarRating;
