import emailjs from 'emailjs-com';

interface EmailOptions {
  name: string;
  email: string;
  description: string;
}

export const sendEmailForQRCode = async ({
  name,
  email,
  description,
}: EmailOptions): Promise<void> => {
  const templateParams = {
    user_name: name,
    user_email: email,
    user_message: description,
    user_subject: 'Costa Rican Air',
    to_email: 'costaricanair@my.successful.company',
    email_year: new Date().getFullYear(),
  };

  try {
    // Send email to CostaRicaAirOrganic's email for QR code feedback
    await emailjs.send(
      'service_3osbjpq', // Your EmailJS Service ID
      'template_vfpjfbi', // New template ID for QR code feedback
      templateParams, // Template parameters with feedback info
      'VLeZJcPkg_SJzndf8' // Your EmailJS User ID
    );

    console.log(
      'QR code feedback email sent successfully to:',
      'costaricanair@my.successful.company'
    );
  } catch (error) {
    console.error('Failed to send QR code feedback email:', error);
    throw error;
  }
};
