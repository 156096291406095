import {Card} from './Card';

export const Services = () => (
  <section
    className='container grid grid-cols-1 lg:grid-cols-3 lg:gap-4 mx-auto mt-[160px] lg:my-10'
    id='services'
  >
    {[
      {
        title: 'Web Application Development',
        icon: 'Display',
        content: (
          <p>
            Our team excels at creating top-tier web applications that captivate
            and engage your audience.
            <br />
            We’re committed to delivering smooth, intuitive experiences that not
            only impress but deeply connect with users.
          </p>
        ),
      },
      {
        title: 'Mobile Application Development',
        icon: 'PhoneFlip',
        content: (
          <p>
            Engage and captivate your target audience with our tailored mobile
            app development solutions.
            <br />
            We create powerful, high-performance iOS and Android apps that
            provide flawless user experiences.
          </p>
        ),
      },
      {
        title: 'Data Engineering Solutions',
        icon: 'DatabaseFillGear',
        content: (
          <p>
            Transform your raw data into a powerful asset that enhances user
            experiences and drives valuable insights.
            <br />
            At Leanware, we design and manage data pipelines, APIs, and business
            intelligence dashboards customized to your unique requirements.
          </p>
        ),
      },
      {
        title: 'UX & UI Design',
        icon: 'Brush',
        content: (
          <p>
            Our designers craft intuitive, visually striking interfaces that
            captivate and engage your audience.
            <br />
            We ensure your digital products are user-friendly, responsive, and
            perfectly aligned with your brand’s vision, elevating user
            satisfaction and experience.
          </p>
        ),
      },
      {
        title: 'Marketing, SEO and Social Media',
        icon: 'FileBarGraph',
        content: (
          <p>
            Boost your brand’s visibility and engagement with our integrated
            marketing, SEO, and social media strategies.
            <br />
            We craft targeted campaigns that improve search rankings, attract
            the right audience, and drive conversions. Let us help you grow your
            online presence and achieve lasting success.
          </p>
        ),
      },
      {
        title: 'Ecommerce and Shopify',
        icon: 'Shop',
        content: (
          <p>
            Build a powerful online store with our Shopify and Ecommerce
            solutions.
            <br />
            Whether you're launching a new brand or scaling an existing one, we
            create custom, high-performing stores that deliver seamless user
            experiences and drive sales. From setup to optimization, we’ll help
            you succeed in the competitive world of online retail.
          </p>
        ),
      },
    ].map((service) => (
      <Card key={service.title} title={service.title} icon={service.icon}>
        {service.content}
      </Card>
    ))}
  </section>
);
