import React, {useState, useEffect} from 'react';
import {sendEmailForQRCode} from '../api/emailQRcode'; // Import the email sending function
import StarRating from '../components/StarRaiting';

interface FormValues {
  name: string;
  email: string;
  description: string;
  ageRange: string; // Add the ageRange field
}

const FeedbackFormCostaRicanAirOrganic: React.FC = () => {
  useEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex';
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    email: '',
    description: '',
    ageRange: '', // Initialize the age range state
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Separate ratings for Smell, Presentation, Long Lasting, and Price
  const [smellRating, setSmellRating] = useState(0);
  const [presentationRating, setPresentationRating] = useState(0);
  const [longLastingRating, setLongLastingRating] = useState(0);
  const [priceRating, setPriceRating] = useState(0); // Add a price rating

  // Handle changes in rating for each category
  const handleSmellRatingChange = (newRating: number) => {
    setSmellRating(newRating);
  };

  const handlePresentationRatingChange = (newRating: number) => {
    setPresentationRating(newRating);
  };

  const handleLongLastingRatingChange = (newRating: number) => {
    setLongLastingRating(newRating);
  };

  const handlePriceRatingChange = (newRating: number) => {
    setPriceRating(newRating); // Update the price rating
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    > // Updated to include select element
  ) => {
    const {name, value} = e.target;
    setFormValues({...formValues, [name]: value});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    // Format the description to include ratings and the age range
    const updatedDescription = `
      Smell: ${smellRating}/5,
      Presentation: ${presentationRating}/5,
      Long Lasting: ${longLastingRating}/5,
      Price: ${priceRating}/5,
      Age Range: ${formValues.ageRange},
      Extra Feedback: ${formValues.description}
    `;

    try {
      await sendEmailForQRCode({
        ...formValues,
        description: updatedDescription.trim(),
      });
      setSuccessMessage(
        'Thank you for your feedback! Your message has been sent successfully.'
      );
      setFormValues({name: '', email: '', description: '', ageRange: ''});
      setSmellRating(0);
      setPresentationRating(0);
      setLongLastingRating(0);
      setPriceRating(0); // Reset price rating
    } catch (error) {
      setErrorMessage('Failed to send feedback. Please try again later.');
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Disable the submit button if any of the required fields are missing
  const isSubmitDisabled =
    isSubmitting ||
    smellRating === 0 ||
    presentationRating === 0 ||
    longLastingRating === 0 ||
    priceRating === 0 ||
    formValues.ageRange === ''; // Ensure age range is selected

  return (
    <div className='max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg mt-10'>
      <div className='flex justify-center my-5 items-center pl-3'>
        <div className='border-r pr-5 mr-5'>
          <img
            src='/assets/brands/logo-print.jpg'
            alt='Costa Rican Air Organic Logo'
            width={120}
          />
        </div>
        <div>
          <h2 className='text-xl font-bold text-yellow-950'>
            Your feedback is incredibly valuable to us, and we truly appreciate
            you taking the time to share it!
          </h2>
        </div>
      </div>

      {successMessage && (
        <p className='text-green-500 mb-4'>{successMessage}</p>
      )}
      {errorMessage && <p className='text-red-500 mb-4'>{errorMessage}</p>}

      <form onSubmit={handleSubmit} className='space-y-4'>
        <div>
          <label
            htmlFor='name'
            className='block text-sm font-medium text-gray-700'
          >
            Name
          </label>
          <input
            type='text'
            name='name'
            id='name'
            value={formValues.name}
            onChange={handleChange}
            required
            className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
          />
        </div>

        <div>
          <label
            htmlFor='email'
            className='block text-sm font-medium text-gray-700'
          >
            Email
          </label>
          <input
            type='email'
            name='email'
            id='email'
            value={formValues.email}
            onChange={handleChange}
            required
            className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
          />
        </div>

        {/* Age Range Dropdown */}
        <div>
          <label
            htmlFor='ageRange'
            className='block text-sm font-medium text-gray-700'
          >
            Age Range
          </label>
          <select
            name='ageRange'
            id='ageRange'
            value={formValues.ageRange}
            onChange={handleChange}
            required
            className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
          >
            <option value=''>Select Age Range</option>
            <option value='-21'>-21</option>
            <option value='22-29'>22-29</option>
            <option value='30-39'>30-39</option>
            <option value='40-49'>40-49</option>
            <option value='50+'>50+</option>
          </select>
        </div>

        {/* Star Ratings */}
        <div>
          <label className='block text-sm font-medium text-gray-700'>
            Smell
          </label>
          <StarRating
            maxRating={5}
            onRatingChange={handleSmellRatingChange}
            currentRating={smellRating}
            required
          />
        </div>

        <div>
          <label className='block text-sm font-medium text-gray-700'>
            Presentation
          </label>
          <StarRating
            maxRating={5}
            onRatingChange={handlePresentationRatingChange}
            currentRating={presentationRating}
            required
          />
        </div>

        <div>
          <label className='block text-sm font-medium text-gray-700'>
            Long Lasting
          </label>
          <StarRating
            maxRating={5}
            onRatingChange={handleLongLastingRatingChange}
            currentRating={longLastingRating}
            required
          />
        </div>

        <div>
          <label className='block text-sm font-medium text-gray-700'>
            Price
          </label>
          <StarRating
            maxRating={5}
            onRatingChange={handlePriceRatingChange}
            currentRating={priceRating}
            required
          />
        </div>

        <div>
          <label
            htmlFor='description'
            className='block text-sm font-medium text-gray-700'
          >
            Additional Comments
          </label>
          <textarea
            id='description'
            name='description'
            value={formValues.description}
            onChange={handleChange}
            rows={4}
            className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
          />
        </div>

        <button
          type='submit'
          disabled={isSubmitDisabled}
          className='w-full py-2 px-4 bg-yellow-950 text-white font-bold rounded-md disabled:opacity-50'
        >
          {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
        </button>
      </form>
    </div>
  );
};

export default FeedbackFormCostaRicanAirOrganic;
