import React from 'react';
import {NavBar} from './components/NavBar';
import {Card} from './components/Card';
import {QuoteForm} from './components/QuoteForm';
import {Helmet} from 'react-helmet';
import {Services} from './components/Services';
const Main = () => {
  return (
    <div>
      <Helmet>
        <title>MySuccessful.Company - Digital Transformation Experts</title>
        <meta
          name='google-site-verification'
          content='c33pABQm6BI6qcIxDTiBMSbfdN5thPNy1U_d-hiVr1o'
        />
        <meta
          name='description'
          content='We offer cutting-edge web and mobile development, data engineering, marketing, and more to make your business thrive. Ideal for startups and enterprises looking for innovative solutions.'
        />
        <meta
          name='keywords'
          content='web development, mobile development, SEO, marketing, ecommerce, data engineering'
        />
        <meta
          property='og:title'
          content='Transform Your Business with MySuccessful.Company'
        />
        <meta
          property='og:description'
          content='Your go-to partner for Custom Web App Development, mobile solutions, and strategic digital innovation.'
        />
        <meta property='og:url' content='https://www.mysuccessful.company' />
        <meta property='og:type' content='website' />
        <link rel='canonical' href='https://www.mysuccessful.company' />
        <script type='application/ld+json'>
          {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "MySuccessful.Company",
        "url": "https://mysuccessful.company",
        "logo": "https://mysuccessful.company/logo.jpg",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+1-858-775-3159",
          "contactType": "Customer Service"
        }
      }
    `}
        </script>
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
      </Helmet>

      <main>
        {/* Hero Section */}
        <section className='container-full bg-hero-pattern bg-no-repeat lg:mt-0 bg-top bg-cover h-[300px] mt-20 lg:h-[820px] w-full'>
          <header>
            <NavBar />
          </header>
          <div className='text-white font-bold mx-auto justify-around lg:p-2 w-full mt-[112px] lg:mt-[540px] bg-theme-gray'>
            <div
              className='d-flex lg:flex items-center container mx-auto p-5 lg:p-0'
              id='stats'
            >
              {/* Stat Cards */}
              {[
                {value: '100%', label: 'CLIENT SATISFACTION'},
                {value: '+1M', label: 'IMPACTED USERS'},
                {value: '+100', label: 'PROJECTS CREATED'},
              ].map(({value, label}) => (
                <div
                  key={label}
                  className='text-center lg:border-r-4 lg:border-r-white w-full flex lg:block items-end justify-center'
                >
                  <div className='text-2xl lg:text-6xl mr-2 lg:mr-0'>
                    {value}
                  </div>
                  <div className='text-base'>{label}</div>
                </div>
              ))}
              {/* Contact Info */}
              <div className='text-center w-full my-5 lg:m-10 '>
                <div className='text-xl'>info@mysuccessful.company</div>
                <div className='text-xl'>Call us at 858-775-3159</div>
              </div>
            </div>
          </div>
        </section>

        {/* Services Section */}
        <Services />

        {/* Quote Form Section */}
        <section
          className='lg:flex items-center bg-quote-pattern'
          id='quoteForm'
        >
          <div className='lg:w-6/12 m-0 lg:m-10 p-10 pb-2 lg:p-0 text-center'>
            <h2 className='text-white text-3xl font-bold'>
              We thrive on tackling new challenges
            </h2>
            <h3 className='text-white text-5xl font-bold text-end'>
              Share yours with us!
            </h3>
            <p className='text-white text-xl font-normal mt-4'>
              Expect a response within 1 business day, guaranteed.
            </p>
          </div>
          <div className='lg:w-6/12 lg:m-10 py-5 lg:py-0'>
            <QuoteForm />
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section className='bg-theme-lightgray pt-20' id='whyChooseUs'>
          <div className='container mx-auto text-center'>
            <h2 className='text-4xl font-bold mb-10'>Why Choose Us?</h2>
            <div className='text-black mx-5 lg:mx-20 mt-5 mb-10 text-base text-center rounded-xl leading-7 bg-white'>
              {'At '}
              <span className='text-theme-blue text-xl font-bold'>
                MySuccessful.Company
              </span>
              , we pride ourselves on being the go-to partner for both startups
              and established enterprises. With a proven track record in app
              development, marketing strategies, and website creation, we help
              bring your vision to life—whether you're launching a new idea or
              expanding a thriving business. Our expert team delivers
              world-class solutions, ensuring every project is crafted to
              captivate and engage your audience. From cutting-edge mobile apps
              to high-performing websites, we provide the tools and strategies
              to fuel your success. Partner with us and experience the power of
              innovation backed by expertise.
            </div>
            <div className='pt-5'>
              <div className='text-theme-blue text-4xl text-center'>
                -Make your company become a sucessful company-
              </div>
              <div className='d-flex lg:flex lg:w-full items-center justify-center'>
                <div className='lg:w-1/3 lg:mr-20'>
                  <img
                    src='/assets/desktop.jpg'
                    alt='Web application development team at work'
                    height={650}
                  />
                </div>
                <div className='lg:w-1/3 text-base p-5 lg:p-0 text-center lg:text-start'>
                  <p>
                    Our company offers comprehensive front-end and back-end
                    solutions tailored for both new startups and established
                    businesses. Whether you're launching your first app or
                    scaling an existing platform, our expert team delivers
                    cutting-edge development that ensures seamless user
                    experiences and robust, scalable architectures.
                  </p>

                  <p>
                    We specialize in modern front-end technologies like React
                    and Vue.js to create visually stunning, user-friendly
                    interfaces. On the back-end, we employ powerful frameworks
                    like Node.js and Python to build secure, high-performance
                    systems. With our expertise, your business gets the best of
                    both worlds—innovation and reliability—making us the ideal
                    partner for your digital transformation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Company Section */}
        <section id='ourCompany'>
          <div className='container mx-auto text-center'>
            <div className='lg:grid lg:grid-cols-3 gap-1 lg:gap-8'>
              {[
                {
                  icon: 'StarFill',
                  title: 'Expertise',
                  content:
                    'Our teams bring extensive expertise in the latest programming languages and frameworks, including React, Node.js, Python, iOS, Android, and more. We stay ahead of industry standards, best practices, and technological innovations to deliver feature-rich, scalable, and future-proof software solutions',
                },
                {
                  icon: 'ShieldLock',
                  title: 'Security',
                  content:
                    'We prioritize your data’s safety by employing robust security measures, including encryption, secure coding practices, and regular vulnerability assessments. Our solutions comply with industry standards like GDPR, ensuring your data remains protected from potential threats.',
                },
                {
                  icon: 'ClockHistory',
                  title: 'Timeliness',
                  content:
                    'We excel at delivering projects within set timelines by leveraging agile methodologies and efficient project management. Our disciplined approach ensures we meet deadlines while maintaining high standards of quality, helping you stay ahead in a fast-paced market.',
                },
                {
                  icon: 'FaPeopleGroup',
                  title: 'Our Team',
                  content:
                    'Our seasoned development team specializes in delivering high-quality software solutions for startups, enterprises, and SaaS platforms. We excel in tackling complex projects across diverse industries, including healthcare, fintech, education, and beyond—empowering innovators with tailored, cutting-edge technology.',
                },
                {
                  icon: 'HiOutlineSpeakerphone',
                  title: 'Comunication',
                  content:
                    'With strong project management and agile development practices, we ensure continuous collaboration and transparency. Our clients benefit from regular status updates, detailed reports, and open communication through meetings, chat tools, and direct access to code repositories—keeping every project aligned and on track.',
                },
                {
                  icon: 'BsClipboardDataFill',
                  title: 'Quality',
                  content:
                    'Our focus on quality assurance, automation, testing, and infrastructure monitoring ensures that every software solution we deliver meets modern standards, scalability requirements, and compliance regulations. This commitment to excellence guarantees reliable, high-performing, and future-ready products.',
                },
              ].map(({icon, title, content}) => (
                <Card key={title} title={title} icon={icon}>
                  <p>{content}</p>
                </Card>
              ))}
            </div>
          </div>
        </section>
        {/* Information Section */}
        <section id='companyInformation'>
          <div className='d-flex lg:flex lg:w-full items-center justify-center'>
            <div className='lg:w-1/3 text-base text-start lg:mr-20 px-5 lg:px-0'>
              <p>
                Our company offers comprehensive front-end and back-end
                solutions tailored for both new startups and established
                businesses. Whether you're launching your first app or scaling
                an existing platform, our expert team delivers cutting-edge
                development that ensures seamless user experiences and robust,
                scalable architectures.
              </p>

              <p>
                We specialize in modern front-end technologies like React and
                Vue.js to create visually stunning, user-friendly interfaces. On
                the back-end, we employ powerful frameworks like Node.js and
                Python to build secure, high-performance systems. With our
                expertise, your business gets the best of both worlds—innovation
                and reliability—making us the ideal partner for your digital
                transformation.
              </p>
            </div>
            <div className='lg:w-1/3'>
              <img
                src='/assets/mobile.jpg'
                alt='Mobile application development team at work'
                height={650}
              />
            </div>
          </div>
          <div className='d-flex lg:flex lg:w-full items-center justify-center'>
            <div className='lg:w-1/3 mr-20 hidden lg:flex'>
              <img src='/assets/data.jpg' alt='data engineering' height={650} />
            </div>
            <div className='lg:w-1/3 text-base text-start px-5 lg:px-0'>
              <p>
                Our company offers comprehensive front-end and back-end
                solutions tailored for both new startups and established
                businesses. Whether you're launching your first app or scaling
                an existing platform, our expert team delivers cutting-edge
                development that ensures seamless user experiences and robust,
                scalable architectures.
              </p>

              <p>
                We specialize in modern front-end technologies like React and
                Vue.js to create visually stunning, user-friendly interfaces. On
                the back-end, we employ powerful frameworks like Node.js and
                Python to build secure, high-performance systems. With our
                expertise, your business gets the best of both worlds—innovation
                and reliability—making us the ideal partner for your digital
                transformation.
              </p>
            </div>
          </div>
        </section>

        {/* Strategies Section */}
        <section id='companyStrategies'>
          <div className='bg-office-pattern bg-cover p-1 pb-5 lg:pb-[500px]'>
            <div className='lg:grid lg:grid-cols-5 lg:gap-1'>
              <Card title='Reduce Costs'>
                <p className='mt-5 lg:mt-10 mb-0'>
                  Not Quality Our strategic location in Latin America allows us
                  to provide cost-effective solutions without compromising on
                  quality. With a team that operates seamlessly across all U.S.
                  time zones, you can count on fast, efficient communication
                  with professionals who understand your needs.
                </p>
              </Card>
              <Card title='Affordable Excellence'>
                <p className='mt-5 lg:mt-10 mb-0'>
                  Leanware delivers the perfect blend of affordability and
                  top-tier performance. Partner with us to experience
                  high-quality, innovative solutions tailored specifically to
                  your goals, all while benefiting from competitive pricing.
                </p>
              </Card>
              <Card title='Proven Expertise'>
                <p className='mt-5 lg:mt-10 mb-0'>
                  With a track record of success, our experienced team knows how
                  to transform your ideas into reality with precision and skill.
                  Every project is rigorously tested and optimized to ensure
                  it’s ready to excel in the real world.
                </p>
              </Card>

              <Card title='Uncompromising Quality'>
                <p className='mt-5 lg:mt-10 mb-0'>
                  We adhere to the highest QA standards, delivering
                  enterprise-grade solutions across industries like fintech,
                  SaaS, and beyond. Quality isn’t just a goal—it’s our
                  guarantee.
                </p>
              </Card>
              <Card title='Cost Efficiency'>
                <p className='mt-5 lg:mt-10 mb-0'>
                  By partnering with our LatAm-based team, you gain access to
                  premium development services at a fraction of the cost. We
                  offer competitive pricing that makes exceptional software
                  accessible, without the financial strain of traditional
                  onshore or offshore providers.
                </p>
              </Card>
            </div>
          </div>
        </section>

        {/* Partners Section */}
        {/* <section className='container mx-auto my-20' id='partners'>
          <h2 className='text-center text-4xl font-bold mb-10'>
            Our Trusted Partners
          </h2>
          <div className='grid grid-cols-2 lg:grid-cols-4 gap-8'>
            {['Partner1', 'Partner2', 'Partner3', 'Partner4'].map((partner) => (
              <div
                key={partner}
                className='flex justify-center items-center p-6 bg-white shadow-lg'
              >
                <img
                  src={`/${partner}.png`}
                  alt={partner}
                  className='w-full h-auto'
                />
              </div>
            ))}
          </div>
        </section> */}
      </main>

      <footer className='bg-theme-gray py-5 px-5 lg:px-0 lg:py-0 text-center'>
        <div className='hidden lg:block bg-gradient-to-tr from-theme-dark-blue to-theme-blue text-white p-5'>
          <ul className='grid grid-cols-3 lg:grid-cols-5 lg:gap-1 gap-4 p-4 text-xs lg:text-base'>
            {[
              {name: 'React', url: 'https://reactjs.org/'},
              {name: 'Next.js', url: 'https://nextjs.org/'},
              {name: 'Node.js', url: 'https://nodejs.org/'},
              {name: 'Express', url: 'https://expressjs.com/'},
              {name: 'NestJS', url: 'https://nestjs.com/'},
              {name: 'NX', url: 'https://nx.dev/'},
              {name: 'AWS', url: 'https://aws.amazon.com/'},
              {
                name: 'Google Bigtable',
                url: 'https://cloud.google.com/bigtable',
              },
              {name: 'GraphQL', url: 'https://graphql.org/'},
              {name: 'Docker', url: 'https://www.docker.com/'},
              {name: 'PostgreSQL', url: 'https://www.postgresql.org/'},
              {name: 'Prisma', url: 'https://www.prisma.io/'},
              {name: 'MongoDB', url: 'https://www.mongodb.com/'},
              {name: 'TailwindCSS', url: 'https://tailwindcss.com/'},
              {name: 'React Native', url: 'https://reactnative.dev/'},
              {name: 'Firebase', url: 'https://firebase.google.com/'},
              {name: 'TypeScript', url: 'https://www.typescriptlang.org/'},
              {name: 'Kubernetes', url: 'https://kubernetes.io/'},
              {name: 'Jest', url: 'https://jestjs.io/'},
              {name: 'Cypress', url: 'https://www.cypress.io/'},
              {name: 'AWS Lambda', url: 'https://aws.amazon.com/lambda/'},
              {
                name: 'Google Cloud Functions',
                url: 'https://cloud.google.com/functions',
              },
              {
                name: 'ElasticSearch',
                url: 'https://www.elastic.co/elasticsearch/',
              },
              {name: 'Redis', url: 'https://redis.io/'},
              {name: 'Jenkins', url: 'https://www.jenkins.io/'},
              {name: 'GitLab CI/CD', url: 'https://about.gitlab.com/'},
              {name: 'Terraform', url: 'https://www.terraform.io/'},
              {name: 'Azure', url: 'https://azure.microsoft.com/'},
              {name: 'Sentry', url: 'https://sentry.io/'},
              {name: 'Webpack', url: 'https://webpack.js.org/'},
              {name: 'Vite', url: 'https://vitejs.dev/'},
              {name: 'Serverless', url: 'https://www.serverless.com/'},
              {name: 'Vercel', url: 'https://vercel.com/'},
              {name: 'Netlify', url: 'https://www.netlify.com/'},
              {name: 'Shopify', url: 'https://www.shopify.com/'},
              {
                name: 'eCommerce',
                url: 'https://en.wikipedia.org/wiki/E-commerce',
              },
            ].map((tech) => (
              <li key={tech.name} className='text-white'>
                <a
                  href={tech.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='hover:underline'
                >
                  {tech.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <p>
          &copy; {new Date().getFullYear()} MySuccessful.Company. All rights
          reserved
        </p>
      </footer>
    </div>
  );
};

export default Main;
